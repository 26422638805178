import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export const ProfileSection = () => {
  return (
    <>
      <div className="relative bg-white">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
            <StaticImage
              className="h-56 w-full object-cover lg:absolute lg:h-full"
              src="../images/you.png"
              alt=""
            />
          </div>
        </div>
        <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div className="lg:col-start-2 lg:pl-8">
            <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
              <h3
                id="profile"
                className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"
              >
                公司简介
              </h3>
              <p className="mt-8 text-lg text-gray-500">
                当往——勇于担当，自今以往！
              </p>
              <p className="mt-3 text-lg text-gray-500">
                DEAIMT——数字经济，人工智能，元宇宙！
              </p>
              <div className="mt-5 text-lg prose prose-indigo text-gray-500">
                <p>
                  当往数智是一家以客户为中心，立足于当下，面向未来的企业。它是一家专注于机器人流程自动化、BPM业务流程管理、AI人工智能技术为核心，致力于为政企客户提供智能的RPA机器人产品与解决方案，通过RPA赋能，持续为客户创造价值，助力企事业数字化转型。
                </p>

                <p>
                  公司团队专注自动化引擎研究，采用了BPM技术中极具价值部分，重点在流程图生成，业务重组等技术上进行突破，结合各种AI技术，实现了独特的流程挖掘和流程优化技术，形成显著优势，为能源、政务、医疗、教育等行业提供了先进的业务流程解决方案，帮助客户实现真正意义上的降本增效。
                </p>
                <p>
                  公司的使命是致力于数字文明，希望能为人类数字文明的建设奉献属于自己的那份力量。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="petri"
        className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:py-12 lg:px-8"
      >
        <h2 className="text-center text-3xl font-extrabold tracking-tight sm:text-4xl">
          理论创新成果
        </h2>

        <div className="mt-8 space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
          <div className="w-200 h-300">
            <StaticImage
              object-fit="contain"
              className="shadow-lg rounded-lg"
              src="../images/wilMP.png"
              alt=""
            />
          </div>
          <div className="sm:col-span-2">
            <div className="space-y-4">
              <div className="text-lg">
                <p className="text-gray-500">
                  创始人余阳教授是中国BPM大会（CBPM）指导委员会7个成员之一，与国际工作流理论创始团队Wil
                  M.P. van der Aalst教授、Arthur H.M. ter
                  Hofstede教授在过程挖掘、云工作流等领域有深入的科研合作。
                </p>
              </div>
            </div>
          </div>
        </div>

        <p className="text-indigo-600 text-center">合作项目/论文</p>

        <ul className="divide-y pt-4 divide-gray-200">
          <li className="py-4 flex bg-gray-50">
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-900">
                无状态云工作流调度及过程协同关键技术研究，广东省科技计划国际科技合作项目（2020A0505100030），2020.9-2022.12
              </p>
            </div>
          </li>
          <li className="py-4 flex">
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-900">
                云工作流关键技术研究及应用，广州市产学研协同创新重大专项（2016201604030001），2016.3-2019.1
              </p>
            </div>
          </li>
          <li className="py-4 flex bg-gray-50">
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-900">
                云工作流平台的开发及应用，广东省科技发展专项国际合作项目（2016B050502006），2016.1-2017.12
              </p>
            </div>
          </li>
          <li className="py-4 flex">
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-900">
                Michael Adams, Chun Ouyang, Arthur H.M. ter Hofstede, and Yang
                Yu. Design and Performance Analysis of Load Balancing Strategies
                for Cloud-Based Business Process Management Systems. Proceedings
                of On the Move to Meaningful Internet Systems OTM 2018
                Conferences — CoopIS2018. October 22-26, 2018, Valletta, Malta.
                pp 390-406. 【CORE-A类会议】
              </p>
            </div>
          </li>
          <li className="py-4 flex bg-gray-50">
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-900">
                Chun Ouyang, Michael Adams, Arthur H. M. ter Hofstede, Yang
                Yu(通讯作者). Towards the Design of a Scalable Business Process
                Management System Architecture in the Cloud. Proceedings of the
                37th International Conference on Conceptual Modeling (ER 2018).
                October 22-25,2018,Xi’an, China. pp 334-348. 【CORE-A类会议】
              </p>
            </div>
          </li>
          <li className="py-4 flex">
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-900">
                Jing Yang, Chun Ouyang, Maolin Pan, Yang Yu(通讯作者) and Arthur
                Ter Hofstede. Finding the Liberos: Discover Organizational
                Models with Overlaps. Proceedings of the 16th International
                Conference on Business Process Management(BPM 2018).September
                9-14,2018,Sydney, Australia. pp 339–355.
                【CORE-A类会议】工作流/BPM领域国际顶级会议
              </p>
            </div>
          </li>
          <li className="py-4 flex bg-gray-50">
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-900">
                Jing Yang,Chun Ouyang,Wil MPvan der Aalstba,Arthur H.M.ter
                Hofstedea, Yang Yuc. A Framework for Discovering,Evaluating,and
                AnalyzingOrganizational Models using Event Logs
              </p>
            </div>
          </li>
        </ul>
      </div>
    </>
  )
}

export default ProfileSection
